.machine-header-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.tab-buttons {
  display: flex;
  gap: 15px;
  margin-bottom: 8px;
  justify-content: center;
}

.tab {
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 20px;
  padding: 6px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab.active {
  background-color: #007bff;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.tab-content-machine {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100vw;
  overflow-x: hidden;
}
