.machine-manager-container {
  width: 98%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 30px 40px;
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow-x: hidden;
}

.search-box {
  width: 50%;
  max-width: 500px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}

.loading, .error {
  font-size: 16px;
  margin-top: 10px;
}

.loading {
  color: #007bff;
}

.error {
  color: red;
}

.table-container {
  max-height: 600px;
  overflow: auto;
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
}

.machine-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border: 1px solid #ddd;
  min-width: 100%;
}

.machine-table thead {
  position: sticky;
  top: 0;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  z-index: 2;
}

.machine-table th, .machine-table td {
  padding: 7px 14px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
}

.machine-table th {
  text-align: center;
}

.machine-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.machine-table tr:hover {
  background-color: #ddd;
  cursor: pointer;
}

.center-data {
  text-align: center;
}

.stats-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
  box-sizing: border-box;
}

.stat-block {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.stat-separator {
  height: 1px;
  background-color: #eee;
  margin: 15px 0;
}

.stat-label {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.stat-value {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin: 10px 0;
}

.stat-value-small {
  font-size: 14px;
  color: #333;
  text-align: left;
  max-height: 200px;
  overflow-y: auto;
}

.province-stat {
  margin: 4px 0;
  padding: 2px 8px;
}

.online-count, .stat-subvalue {
  font-size: 14px;
  color: #4CAF50;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .machine-manager-container {
    width: 100%;
    padding: 10px;
  }

  .search-box {
    width: 90%;
  }

  .stats-container {
    flex-direction: column;
    align-items: center;
  }

  .stat-block {
    width: 100%;
    max-width: 300px;
  }
}

.search-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}

.download-csv-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.download-csv-btn:hover {
  background-color: #0056b3;
}

.download-csv-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.download-csv-btn:disabled:hover {
  background-color: #cccccc;
}