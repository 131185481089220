.machine-details-container {
  display: flex;
  gap: 20px;
  width: 98%;
  max-width: 1800px;
  margin: 0 auto;
  height: calc(100vh - 110px);
  box-sizing: border-box;
  padding: 10px 0;
}

.left-panel {
  flex: 1;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.right-panel {
  flex: 1;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.details-search-box {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 14px;
}

.details-table-container {
  flex: 1;
  overflow: auto;
  width: 100%;
  height: calc(100vh - 200px);
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border: 1px solid #ddd;
}

.details-table thead {
  position: sticky;
  top: 0;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  z-index: 2;
}

.details-table th, .details-table td {
  padding: 7px 14px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
}

.details-table th {
  cursor: pointer;
  user-select: none;
  text-align: center;
  transition: background-color 0.2s;
}

.details-table th:hover {
  background-color: #0056b3;
}

.details-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.details-table tr:hover {
  background-color: #ddd;
  cursor: pointer;
}

.details-table tr.selected {
  background-color: #007bff !important;
  color: white;
}

.details-table tr.selected:hover {
  background-color: #0056b3 !important;
}

.center-data {
  text-align: center;
}

.machine-info {
  height: 100%;
}

.no-selection {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 16px;
}

.details-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);  /* Two equal columns */
  grid-template-areas: 
    "info stats"     /* First row: info and stats blocks */
    "playlists playlists"; /* Second row: playlists block spans both columns */
  max-width: 100%;
}

/* Add these classes for explicit grid placement */
.details-block.info-block {
  grid-area: info;
}

.details-block.stats-block {
  grid-area: stats;
}

.details-block.playlists-block {
  grid-area: playlists;
}

.details-block {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  min-width: 250px;
}

.details-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 2px solid #007bff;
}

.details-content {
  font-size: 14px;
}

.details-content p {
  margin: 8px 0;
  color: #666;
}

.details-content strong {
  color: #333;
  font-weight: 600;
}

.details-spacer {
  height: 15px;
}

.maps-link {
  display: inline-block;
  margin-top: 15px;
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.maps-link:hover {
  text-decoration: underline;
}

.playlist-count {
  color: #666;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: right;
}

.playlist-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 8px;
}

.playlist-item {
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.playlist-item input[type="checkbox"] {
  margin-right: 8px;
}

.playlist-item label {
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.playlist-search {
  margin-bottom: 15px;
}

@media (max-width: 1200px) {
  .machine-details-container {
    flex-direction: column;
    height: auto;
  }

  .left-panel, .right-panel {
    width: 100%;
  }

  .details-table-container {
    max-height: 400px;
  }
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #007bff;
  font-size: 16px;
  padding: 20px;
}

.loading-state p {
  margin: 0;
  text-align: center;
}

.history-download-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.history-input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.history-days-input {
  width: 80px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.history-days-input::-webkit-inner-spin-button,
.history-days-input::-webkit-outer-spin-button {
  opacity: 1;
}

.history-days-label {
  color: #666;
  font-size: 14px;
}

.download-history-btn {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.download-history-btn:hover {
  background-color: #0056b3;
}

.download-history-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
